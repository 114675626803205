
import {Vue, Component, Prop, Mixins} from 'vue-property-decorator';
import AktarDetayUstBasliklar from "@/components/infos/raporlama-ofispro/helper-comps/AktarDetayUstBasliklar.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
  components: {AktarDetayUstBasliklar}
})
export default class AktarDetay extends Mixins(ObjectInputMixin) {

  items:any = []

  cards: {
    id: number;
    icon: string;
    title: string;
    number: string | number;
    subtext: string | null;
    backgroundColor: string;
  }[] = [];
  aktarilmayanAlanlar:any = []
  messages:string = ''



  mounted() {
    this.fetchData().then(()=>{

      console.log(this.items[0])
      this.getAktarilmayanAlanlar()
    })


  }


  async fetchData(){
    await this.$http.get('/api/v1/job-details/' +  this.$route.params.id).then(res => {
      this.items.push(res)
    })


  }
  hasNonNullValues(array: any[]): boolean {
    return array.some(item => item !== null && item !== undefined && item !== '');
  }
  getAktarilmayanAlanlar() {
    this.items[0].message.forEach((mesaj: any) => {
      let hataAdlari: any = mesaj.messageReports
          .filter((hata: any) => !hata.sucsess)
          .map((hata: any) => hata.HataAdi);

      if (hataAdlari.length === 0) {
        this.aktarilmayanAlanlar.push([]);
      } else {
        this.aktarilmayanAlanlar.push(hataAdlari);
      }
    });
  }
}
